@font-face {
  font-family: "Prompt";
  src: url("assets/fonts/Prompt-Light.ttf");
}

* {
  font-family: "Prompt";
}

body {
  margin: 0;
  overflow-y: overlay;
}

#root {
  height: 100%;
}

*::-webkit-scrollbar {
  display: block;
  width: 16px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-track-piece {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: transparent;
}
